.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html{
  background-color: #4017AE;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-login {
  background-color: #4017AE;
  border: none;
  color: #12F0DE;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 9px;
  margin-top: 5rem;
}

.forgot-password{
  color: #4017AE;
  font-size: 16px;
  text-decoration: none;
}

.mx-5 {
  margin-left: 5rem !important;
}

.welcome-login{
  margin-left: 4rem;
}

.dashboard {
  background-color: #4017AE;
}

.sidebar {
  height: 100%;
  width: 248px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;
  background-color: #4017AE;
}

.sidebar a {
  padding: 10px 30px 10px 30px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

/* Header */
.header {
  background-color: #4017AE;
  height: 95px;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 200px;
  text-align: center;
  padding-top: 20px;
}

/* Main content */
.main {
  margin-top: 60px;
  margin-left: 250px;
  padding: 20px;
  height: max-content;
  color: white;
}

.logo-dash {
  Padding: 10px 24px 30px 0px;
}

.btn{
  Width:200px;
  /* Left:1px; */
  Padding: 30px 24px 30px 24px;
  Gap:10px;
  
}





#hover:hover{
  background-color: #F3F5F8;
  border-radius: 9px;
  color: #4017AE;
}

/* .custom-select {
  padding: 10px 20px;
  color: #12F0DE;
}

.customSelect {
  border: 1px solid #ced4da;
  background-color: #f8f9fa;
}
.customSelect:focus,
.customSelect option:selected {
  background-color: #f8f9fa;
} */

.bg-image{
  background-image: url('./img/majormedia-galerie-1-scaled\ 1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 753px; */
}

.pie-chart{
  height: 420px;
}

.content-kpis
{
  font-weight: 500;
}

input[type="range"]::-webkit-slider-thumb {
  background-color: #4017AE; 
  border-color: #4017AE; 
}

.apexcharts-tooltip-title , .apexcharts-menu-item{
  color: black;
}

.apexcharts-tooltip-series-group{
  color: black;
}

.apexcharts-toolbar {
  position: absolute;
  z-index: 0;
}

body {
  background-color: #fbfbfb;
}
@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0; /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100px;
  }
  .btnLink  span{
    display: none;
  }
  .nav-item{
    width: 80px;
  }

  a.btnLink {
    padding: 10px;
    display: flex;
    justify-content: center;
  }
}
.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.header-dash{
  background-color: #4017AE;
}



.list-status{
  list-style: none;
}

.color-working{
  width: 20px;
  height: 20px;
  background-color: #64C38E;
  border-radius: 5px;
}

.color-Lunch{
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #F2BC8C;
}

.color-Break{
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #EF9EA0;
}

.color-InMeeting{
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #4680CD;
}

.color-Offline{
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #BEBEBE;
}

.list-status li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.status-content{
  padding-left: 10px;
  padding-right: 10px;
}

.MuiSlider-track {
  background-color: red; /* Set the color for the selected ranges */
}

.input-group{
  background-color: #ECEEF1;
  border-radius: 10px;
}


.sort-select{
  width: 250px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  padding: .5rem !important;
}

.label-sort{
  width: 100px;
  color: #7E7E7E;
}

.input-sort{
  width: 100px;
}

.status-activity{
  /* width: 150px; */
  padding: 3px;
  border-radius: 5px;

}


.pagination{
  width: 125%;
  height: 50px;
  padding: 10px;
  background-color: #3f17ae14;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 16px;
}

.pagination-item{
  background-color: white;
  color: black;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-activity{
  padding-left: 40px;
  padding-right: 40px;
  height: 405px;
}

.select-filter{
  width: 220px;
}

.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: #4017AE;
}

.loader {
  margin: auto;
  border: 5px dotted #dadada;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sidebar .active{
  background-color: #F3F5F8;
  border-radius: 9px;
  color: #4017AE;
}


.active-link{
  background-color: white;
  color: black;
  border-radius: 9px;
}


.desactive-link{
  background-color: white;
  color: black;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.page-link{
  cursor: pointer;
}

.section-membres{
  height: 636px;
}

.section-table-pagination{
  height: 475px;
}

/* .MuiSlider-thumb[data-index="0"]{
  color: #64C38E;
}

.MuiSlider-thumb[data-index="1"]{
  color: #EF9EA0;
}

.MuiSlider-thumb[data-index="2"]{
  color: #4680CD;
}

.MuiSlider-thumb[data-index="3"]{
  color: #F2BC8C;
}


.MuiSlider-thumb[data-index="4"]{
  color: #BEBEBE;
} */

.css-14pt78w-MuiSlider-rail:first-child {
  /* opacity: 1; */
  color: #64C38E;
}

.css-14pt78w-MuiSlider-rail:last-child {
  /* opacity: 1; */
  color: #4017AE;
}

/* .css-1gv0vcd-MuiSlider-track {
  color: #64C38E;
} */

.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 100vh;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}


.section-span{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}


/* 497 */
.img-select{
  margin-left: 3rem;
}

@media (max-width: 497px) {
  .img-select{
    margin-left:0;
  }
}

/* .nextPevious{
  background-color: #4017AE;
  color: white;
  border-radius: 3px;
}


.linknextPevious{
  padding: .375rem .75rem;
  position: relative;
    color: white;
    text-decoration: none;
    background-color: #4017AE;
    border-radius: 3px;
} */

.nextpeviousLink{
  border: 0;
    font-size: .9rem;
    border: 0;
    outline: 0;
    border-radius: .25rem;
    padding: .30rem .40rem;
  background-color: #4017AE;
  color: white;
}
.nextpeviousLink:hover{
  color: white;
}

.pagenextpeviousLink{
  border: 0;
    font-size: .9rem;
    border: 0;
    outline: 0;
    border-radius: .25rem;
    padding: .30rem .40rem;
    background-color: white;
    color: #4017AE;
}

.pagenextpeviousLink:active{
  background-color: white;
  color: #4017AE;
}

.pagination .page-link {
  padding: 5px 10px;
  background-color: white;
  color: #4017AE;
  text-decoration: none;
  border-radius: 3px;
}

.pagination .page-item.active .page-link{
  background-color: white;
  color: #4017AE;
  padding: 7px 12px;
}

.logout{
  cursor: pointer;
}


.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.not-found-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 16px;
}

.not-found-message {
  font-size: 18px;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left, .apexcharts-legend.apx-legend-position-top.apexcharts-align-left, .apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left{
  text-align: justify;
}

@media (max-width: 1200px) {
  .line-chart-component{
    width: 550px;
  }

  .pie-chart-component{
    width: 500px;
  }

  .line-chart , .pie-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .barChart{
    width: 600px;
  }

}

@media (max-width: 767px) {
  .line-chart-component{
    width: 450px;
  }

  .pie-chart-component{
    width: 450px;
  }

  .line-chart , .pie-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .barChart{
    width: 450px;
  }
}

